<template>
  <div class="text-center">
    <h1>404</h1>
    <h2>Helaas hebben we deze pagina niet kunnen vinden.</h2>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>